body,
div,
html,
main {
  ::-webkit-scrollbar {
    width: 14px;
    height: 18px;
  }

  ::-webkit-scrollbar-thumb {
    height: 6px;
    // border: 4px solid rgba(255, 255, 255, .25);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(255, 255, 255, .25);
    -webkit-box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.05), inset 1px 1px 0 rgba(0, 0, 0, 0.05);
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
