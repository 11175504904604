
body,
html {
	margin: 0;
	background-color: pink;
	color: green;

	$break: 800px;
	@media only screen and (max-width: $break) {
		font-size: 16pt;
	}
	@media only screen and (min-width: $break) {
		font-size: 2vw;
	}
}

main {
	height: 100vh;
	position: relative;

	input {
		width: 2.7em;
		text-align: right;

		margin: 0 0.4em;
		padding: 0 0.2em;
		border-radius: 0.4em;
		border: dashed 1px green;
		background: none;
		color: green;
		font-size: 1em;
	}
}

article {
	margin: 0;
	position: absolute;
	min-width: 50vw;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
